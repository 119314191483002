@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@400;500;600;700;800&display=swap');

body::-webkit-scrollbar {
  display: none;
}

.App {
  text-align: center;
  font-family: 'Assistant', sans-serif;
  background-color: #e2e2e2;
  min-height: 100vh;
}

.sidebar_title {
  color: #f3be17;
  font-weight: 600;
  text-align: right;
  margin-right: 14px;
  margin-top: 2px;
}

.sidebar_link {
  text-decoration: none !important;
}

.sidebar_title_2 {
  text-align: right;
  margin-right: 23px;
  margin-top: 8px;
  font-size: 15px;
}

.sidebar_title_button {
  color: #b79941;
  font-weight: 700;
  border: 0px solid;
  background-color: #ffffff00;
  padding: 0px;
}

.menu_item {
  color: #93a392;
  text-align: right;
  font-size: 15px;
  height: 24px !important;
}

.ps-menuitem-root {
  height: 22px;
}

.ps-menu-button:hover {
  background-color: black !important;
}

.ps-menu-button {
  font-size: 16px;
  padding: 12px !important;
  height: 15px !important;
}

.title_head {
  height: 70px;
  width: 100%;
  background-color: white;
}

.text_bolt {
  font-weight: 800;
  color: #666666;
}

.dashboard_title_head {
  height: 80px;
  width: 100%;
  background-color: white;
  line-height: 24px;
}

.div_title_head {
  padding-right: 5px;
  margin-top: 15px;
  margin-right: 15px;
  height: 40px;
}

.title_head_text {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}

.title_head_text_2 {
  font-weight: 600;
  color: #666666;
  font-size: 12px;
}

.title_head_text_3 {
  font-weight: 600;
  color: #666666;
  font-size: 12px;
}

.div_title_head_user {
  border-right: #999999 10px solid;
  padding-right: 5px;
  margin-top: 15px;
  margin-right: 30px;
  height: 40px;
  padding-top: 7px;
}

.title_head_text_user {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #999999;
}

.ba_body {
  font-size: 14px;
  text-align: right;
  width: 100% !important;
  min-height: 100%;
  position: relative;
  min-height: 100vh;
}

.container {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.div_button_save {
  border-top: #d7d7d7 1px solid;
  text-align: center;
  padding: 15px;
}

.button_save {
  background-color: #ffe38c;
  border: 1px solid;
  border-radius: 15px;
  padding: 3px;
  font-size: 14px;
  font-weight: 600;
}

.button_dashboard_add_product {
  background-color: #ffffff00;
  border: 0px solid;
  padding: 3px;
  font-weight: 600;
  text-align: right;
}

.accordion-button {
  font-weight: 600 !important;
  border-radius: 15px !important;
  font-size: 19px !important;
  background-color: #f9f9f9 !important;
  color: black !important;
}

.accordion-button:focus {
  box-shadow: none !important;
  background-color: #f9f9f9 !important;
}

.accordion-button::after {
  position: absolute;
  left: 14px;
}

.accordion-item {
  border: #d7d7d7 2px solid !important;
  border-radius: 15px !important;
  background-color: white !important;
  margin-top: 20px;
}

.accordion-button:not(.collapsed) {
  color: black !important;
  background-color: #f9f9f9 !important;
  font-weight: 600;
  font-size: 19px;
  border-radius: 15px 15px 0px 0px !important;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 15px !important;
  background-color: white !important;
  color: black !important;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 15px !important;
  background-color: #f9f9f9 !important;
  font-weight: 600;
  font-size: 19px;
  color: black !important;
}

.accordion-item:first-of-type .accordion-button.collapsed {
  border-radius: 15px !important;
  background-color: #f9f9f9 !important;
  font-weight: 600;
  font-size: 19px;
}

.accordion-item:first-of-type .accordion-button {
  border-radius: 15px 15px 0px 0px !important;
  background-color: #f9f9f9 !important;
  font-weight: 600;
  font-size: 19px;
}

.accordion-body {
  padding: 0px !important;
}

.aco_body {
  padding: 20px;
}

.aco_body_2 {
  font-weight: 600;
  color: black;
  margin-top: 15px;
}

.form-label {
  margin-bottom: 4px !important;
  font-size: 15px !important;
  font-weight: 600;
}

.label {
  margin-bottom: 0px !important;
}

.form_control_radus {
  border-radius: 15px 0px 0px 15px !important;
  border: #302f2f88 1px solid !important;
}

.div_logo {
  border: #302f2f88 1px solid !important;
}

.aco {
  padding: 24px;
}

.div_logo_nav {
  margin: 15px;
}

.logo_nav {
  width: 170px;
}

.logo_nav_2 {
  margin-bottom: 10px;
  margin-right: 20px;
}

.link_nav_3 {
  text-align: right;
}

.logo_nav_3 {
  margin-right: 20px;
  color: #ffa800;
}

.sidebar_title_3 {
  color: #ffa800;
  margin-right: 8px;
  font-size: 14px;
}

.css-1pkkfjc {
  overflow-y: hidden !important;
}

.div_button_booklet {
  margin-top: 6px;
  margin-bottom: 10px;
}

.button_booklet {
  padding: 2px 35px 5px 35px !important;
  width: 100% !important;
  color: #f3be17 !important;
  margin-bottom: 20px !important;
  background-color: #f9f9f9;
  border-radius: 15px;
  height: 5px !important;
  border: 1px solid #f3be17;
  text-decoration: none;
  font-size: 15px;
}

.input_logo {
  padding: 10px;
  width: 100%;
  color: #f3be17;
  margin-bottom: 0px;
  margin-top: 10px;
}

.input_logo::file-selector-button {
  display: none;
}

.input_logo::before {
  background-color: #f9f9f9;
  border: 1px solid;
  border-radius: 15px;
  padding: 3px;
  font-size: 14px;
  font-weight: 600;
  content: 'בחירת קובץ הלוגו';
  margin-left: 25px;
  padding: 5px 40px 5px 40px;
}

.image_text {
  margin-top: 10px;
}

.tytle_dashboard {
  font-size: 24px;
  font-weight: 600;
  margin-top: 14px;
}

.tytle_dashboard_2 {
  font-weight: 600;
  color: #666666;
}

.modal-header {
  background-color: #f9f9f9 !important;
  font-family: 'Assistant' !important;
}


.modal-header .btn-close {
  margin: 0px !important;
}

.h4 {
  font-weight: 600 !important;
  font-size: 20px !important;
  color: black !important;
}

.modal-content {
  font-family: 'Assistant' !important;
  font-weight: 600 !important;
  color: #757575 !important;
  font-size: 14px;
  white-space: pre-line;
}

.modal_bolt_1 {
  font-family: 'Assistant' !important;
  font-weight: 600 !important;
  color: black !important;
  font-size: 15px;
  margin-bottom: 8px;
}

.modal_bolt_2 {
  font-family: 'Assistant' !important;
  font-weight: 600 !important;
  color: black !important;
  font-size: 15px;
  margin-top: 30px;
  margin-bottom: 8px;
}

.modal_bolt_3 {
  font-family: 'Assistant' !important;
  font-weight: 600 !important;
  color: black !important;
  font-size: 15px;
  margin-bottom: 8px;
}

.modal_bolt_3 {
  font-family: 'Assistant' !important;
  font-weight: 600 !important;
  color: black !important;
  margin-top: 20px;
  margin-bottom: 5px;
}

.booklet {
  font-weight: 600 !important;
  color: black !important;
  margin-top: 20px;
  line-height: 14px;
}

.accordion-item {
  font-weight: 600 !important;
  color: #757575 !important;
}

.button_question {
  background-color: #00000000;
  border: 0px solid;
  color: #6f11aa;
  border-radius: 15px;
  padding: 3px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 45px;
}

.button_question_2 {
  background-color: #00000000;
  border: 0px solid;
  color: #6f11aa;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
}

.margin_10 {
  margin-top: 10px;
}

.button_question_logo_1 {
  background-color: #00000000;
  border: 0px solid;
  color: #6f11aa;
  border-radius: 15px;
  padding: 0px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 3px;
}

.button_question_logo_2 {
  background-color: #00000000;
  border: 0px solid;
  color: #6f11aa;
  border-radius: 15px;
  padding: 0px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
}

.label_modal {
  color: black;
}

.text_table {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

table {
  table-layout: fixed;
  width: 100%;
}

td>a {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

td>a>span {
  white-space: nowrap;
}

.color_m {
  color: #5e4fa2;
}

.div_color_m {
  border-right: #5e4fa2 10px solid;
}

.color_b {
  color: #5185c5ed;
}

.div_color_b {
  border-right: #5185c5ed 10px solid;
}

.color_k {
  color: #f598ab;
}

.div_color_k {
  border-right: #f598ab 10px solid;
}

.color_p {
  color: #f04e2a;
}

.div_color_p {
  border-right: #f04e2a 10px solid;
}

.color_c {
  color: #ffa800;
}

.div_color_c {
  border-right: #ffa800 10px solid;
}

.div_dashboard_logo {
  border-radius: 50px;
  width: 60px;
  height: 60px;
  margin-top: 8px;
  text-align: left;
  margin-left: 10px;
  align-items: left;
  float: left;
}

.div_dashboard_logo_2 {
  border-radius: 50px;
  width: 60px;
  height: 60px;
  margin-top: 8px;
  text-align: center;
  align-items: center;
  margin: auto !important;
  padding: auto !important;
}

.div_dashboard_logo_3 {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  margin-top: 8px;
  text-align: center;
  align-items: center;
  margin: auto !important;
  padding: auto !important;
}

.button_dashboard_logo {
  background-color: #00000000;
  border: 0px solid;
  margin: auto !important;
  padding: auto !important;
}

.dashboard_logo {
  border: 1px solid black;
  border-radius: 50px;
  width: 60px;
  height: 60px;
  text-align: left;
}

.dashboard_logo_2 {
  border: 1px solid black;
  border-radius: 50px;
  width: 60px;
  height: 60px;
  margin-top: 10px;
}

.dashboard_logo_3 {
  border: 1px solid black;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  margin-top: 10px;
}

.target {
  margin: 30px 30px 18px 30px;
  background-color: white;
  border-radius: 15px;
  height: 400px;
  white-space: pre-line;
  line-height: 24px;
}

.target2 {
  margin: 0px 30px 0px 0px;
  background-color: white;
  border-radius: 15px;
  height: 200px;
  white-space: pre-line;
  line-height: 24px;
}

.target3 {
  margin: 0px 0px 0px 30px;
  background-color: white;
  border-radius: 15px;
  height: 200px;
  white-space: pre-line;
  line-height: 24px;
}

.target4 {
  margin: 18px 30px 30px 30px;
  background-color: white;
  border-radius: 15px;
  height: 445px;
  white-space: pre-line;
  line-height: 14px;
}

.target_head {
  border-radius: 15px 15px 0px 0px;
  height: 50px;
  background-color: #f9f9f9;
  padding: 10px 20px 10px 10px;
}

.dashboard_head_div_1 {
  border-right: 10px solid #f04e2a;
  height: 28px;
}

.dashboard_head_text_1 {
  color: #f04e2a;
  font-size: 17px;
  font-weight: 700;
  margin-right: 8px;
}

.dashboard_head_div_2 {
  border-right: 10px solid #5185c5ed;
  height: 28px;
}

.dashboard_head_text_2 {
  color: #5185c5ed;
  font-size: 17px;
  font-weight: 700;
  margin-right: 8px;
}

.dashboard_head_div_3 {
  border-right: 10px solid #5e4fa2;
  height: 28px;
}

.dashboard_head_text_3 {
  color: #5e4fa2;
  font-size: 17px;
  font-weight: 700;
  margin-right: 8px;
}

.dashboard_head_div_4 {
  border-right: 10px solid #f598ab;
  height: 28px;
  line-height: 24px;
}

.dashboard_head_text_4 {
  color: #f598ab;
  font-size: 17px;
  font-weight: 700;
  margin-right: 8px;
  line-height: 24px;
}

.div_button_edit {
  text-align: left;
  margin-left: 12px;
}

.button_edit {
  color: #666666;
  background-color: white;
  border-radius: 15px;
  font-size: 11px;
  font-weight: 700;
  padding: 0px 10px 0px 10px;
  border: 2px solid #666666;
}

.button_edit_2 {
  color: #666666;
  background-color: white;
  border-radius: 15px;
  font-size: 11px;
  font-weight: 700;
  padding: 0px 10px 0px 10px;
  border: 2px solid #666666;
  height: 26px;
}

.icon_edit {
  margin-left: 1px;
  font-size: 12px;
}

.dashboard_div_text {
  font-weight: 600;
}

.dashboard_div {
  padding: 5px;
  height: 100px;
  background-color: white;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 2px;
  border: 1px solid black;
  margin-bottom: 6px;
  position: relative;
  overflow: scroll;
  overflow-x: hidden;
}

.dashboard_div_long {
  padding: 5px;
  height: 346px;
  background-color: white;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 2px;
  border: 1px solid black;
  position: relative;
  overflow: scroll;
  overflow-x: hidden;
}

.dashboard_div_none {
  color: #a48e8e;
  font-weight: 600;
  padding: 5px;
  height: 100px;
  background-color: #f0f0f0;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 2px;
  border: 1px solid black;
  margin-bottom: 6px;
  position: relative;
  overflow: scroll;
  overflow-x: hidden;
}

.dashboard_div_none_long {
  color: #a48e8e;
  font-weight: 600;
  padding: 5px;
  height: 346px;
  background-color: #f0f0f0;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 2px;
  border: 1px solid black;
  margin-bottom: 6px;
  position: relative;
  overflow: scroll;
  overflow-x: hidden;
}

.dashboard_marketing {
  padding: 20px;
}

.button_link {
  background-color: none;
  border: 0px solid;
  border-radius: 15px;
  font-weight: 600;
  color: #a48e8e;
  text-decoration: underline;
}

.dashboard_copy_1 {
  text-align: left;
}

.dashboard_copy_2 {
  display: inline;
}

.modal-xl {
  margin-right: 21% !important;
}

.modal-md {
  margin-right: 42% !important;
}

.modal-sm {
  margin-right: 48% !important;
}

.dashboard_button_copy {
  background-color: #ffffff00;
  border: 0px solid;
  color: #a5a5a5;
}

.dashboard_property {
  padding: 15px;
}

.dashboard_property_text {
  position: relative;
  margin-bottom: 6px;
  color: #a48e8e;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  border: 1px solid;
  border-radius: 0px 15px 15px 0px;
  padding: 0px 9px 0px 6px;
}

.dashboard_property_none {
  position: relative;
  margin-bottom: 6px;
  color: #a48e8e;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  border: 1px solid;
  border-radius: 0px 15px 15px 0px;
  padding: 0px 9px 0px 6px;
  background-color: #f0f0f0;
}

.dashboard_property_icon {
  margin-left: 10px;
  font-size: 16px;
}

.dashboard_property_icon_2 {
  margin-left: 10px;
  font-size: 18px;
}

.dashboard_business_name {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-top: 15px;
}

.dashboard_business_name_2 {
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  margin-top: 12px;
  line-height: 16px;
}

.dashboard_slogan {
  color: #666666;
  text-align: center;
  font-size: 16px;
}

.dashboard_slogan_2 {
  color: #666666;
  text-align: center;
  font-size: 11px;
  line-height: 11px;
}

.div_button_add {
  padding: 12px 8px 5px 5px;
}

.button_td_close {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}

.button_add_product_target {
  background-color: #00000000;
  border: 0px solid;
  border-radius: 15px;
  padding: 0px;
  font-size: 14px;
  font-weight: 600;
}

.button_add_product_target_2 {
  background-color: #00000000;
  border: 0px solid;
  border-radius: 15px;
  padding: 0px;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

.button_add_product_target_3 {
  background-color: #00000000;
  border: 0px solid;
  border-radius: 15px;
  padding: 0px;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  text-align: right;
}

.icon_add_product_target {
  margin-left: 6px;
}

.dashbord_table {
  height: 350px;
}

.dashbord_table_2 {
  max-height: 260px;
  overflow: scroll;
  overflow-x: hidden;
}

.table_title {
  margin-left: 20px !important;
  font-weight: 600;
  margin-left: 100px !important;
}

.table_title_1 {
  width: 180px;
  padding: 5px 8px 5px 5px;
}

.table_title_2 {
  width: 180px;
  padding: 5px 8px 5px 5px;
}

.table_title_3 {
  width: 180px;
  padding: 5px 4px 5px 5px;
}

.table_title_4 {
  width: 180px;
  padding: 5px 4px 5px 5px;
}

.table {
  margin-bottom: 0px !important;
}

.div_icon_plus {
  text-align: right;
  margin-right: 4px;
}

.div_icon_minus {
  text-align: left;
  margin-left: 4px;

}

.charts {
  border-radius: 50%;
  padding: 6px 12px;
  background-image: linear-gradient(0deg, #ffb11b 0%, #f1512a 75%, #c0c0c0 100%);
  /* Fill the inside with white */
  background-origin: border-box;
  box-shadow: inset 0 1000px white;
  /* A transparent border, so the very edge of the button shows through */
  border: 10px solid transparent;
  height: 120px;
  width: 120px;
}

@property --p {
  syntax: '<number>';
  inherits: true;
  initial-value: 1;
}

.pie {
  --p: 10;
  --b: 13px;
  --c: #f04e28;
  --w: 160px;

  width: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  place-content: center;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  text-align: center;
  align-items: center;
}

.pie:before,
.pie:after {
  content: "";
  position: absolute;
  border-radius: 50%;
}

.pie:before {
  inset: 0;
  background:
    radial-gradient(farthest-side, #ffb11b 98%, #0000) top/var(--b) var(--b) no-repeat,
    conic-gradient(#ffb11b, #f04e28 calc(var(--p)*1%), #0000 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
  mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
}

.pie:after {
  inset: calc(50% - var(--b)/2);
  background: var(--c);
  transform: rotate(calc(var(--p)*3.6deg)) translateY(calc(50% - var(--w)/2));
}

.animate {
  animation: p 1s .5s both;
}

.no-round:before {
  background-size: 0 0, auto;
}

.no-round:after {
  content: none;
}

@keyframes p {
  from {
    --p: 0
  }
}

.div_pie {
  background-color: #b3bdb0;
  width: 160px;
  border-radius: 50%;
  text-align: center;
  align-items: center;
  margin: auto;
  margin-top: 8px;
  line-height: 14px;
}

.text_pie {
  background-color: #ffffff;
  width: 134px;
  height: 134px;
  border-radius: 50%;
}

.div_target {
  text-align: center;
}

.target_text_1 {
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  line-height: 14px;
}

.target_text_2 {
  font-size: 12px;
  font-weight: 600;
  margin-top: 38px;
  color: #f04e26;
}

.target_text_3 {
  font-size: 28px;
  font-weight: 800;
  margin-top: 4px;
  color: #f04e26;
}

.target_text_4 {
  font-size: 13px;
  font-weight: 600;
  margin-top: 12px;
}

.target_text_5 {
  font-size: 16px;
  font-weight: 600;
  margin-top: 12px;
}

.hovertext {
  position: relative;
  white-space: pre-line;
}

.hovertext:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: 200px;
  background-color: white;
  color: #757575;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  border: 1px solid #757575;
  border-radius: 5px;
  padding: 5px;
  transition: opacity 1s ease-in-out;

  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 110%;
  white-space: pre-line;
}

.hovertext:hover:before {
  opacity: 1;
  visibility: visible;
  white-space: pre-line;
}

.icon_question {
  color: #f9a50a;
  margin-right: 10px;
  font-size: 16px;
  font-size: 20px;
}

.icon_question_2 {
  color: #6f11aa;
  margin-right: 10px;
  font-size: 16px;
}

.button_close {
  margin-right: 15px;
}

.td_close {
  width: 80px;
  text-align: center;
}

.logo_model {
  margin-top: 13px;
}

.Signup {
  background-color: white;
  min-height: 100vh;
  position: relative;
}

.Login {
  background-color: white;
  min-height: 100vh;
  position: relative;
}

.ResetPassword {
  background-color: white;
  min-height: 100vh;
  position: relative;
}

.signup_tytle {
  font-size: 40px;
  font-weight: 600;
  color: #f3be17;
  margin-top: 40px;
  margin-bottom: 10px;
  text-align: center;
}

.login_tytle {
  font-size: 40px;
  font-weight: 600;
  color: #f3be17;
  margin-top: 120px;
  margin-bottom: 20px;
  text-align: center;
}

.password_tytle {
  font-size: 40px;
  font-weight: 600;
  color: #f3be17;
  margin-top: 170px;
  margin-bottom: 20px;
  text-align: center;
}

.div_lable {
  display: block;
  padding-right: 10px;
  padding-left: 22px;
  text-indent: -22px;
  margin-top: 8px;
}

.input_c {
  margin-right: 12px;
  height: 18px !important;
  width: 18px !important;
  vertical-align: middle;
}

.input_c_lable {
  margin-right: 5px;
  font-weight: 600;
  vertical-align: middle;
}

.text_private {
  font-size: 12px;
  line-height: 12px;
  color: #757575;
  margin-top: 10px;
  margin-bottom: 10px;
}

.text_delete {
  font-size: 13px;
  line-height: 14px;
  color: #757575;
  margin-top: 17px;
}

.div_signup_link {
  margin-top: 30px;
  font-size: 13px;
  text-align: center;
  font-weight: 600;
}

.signup_link {
  color: black;
}

.button_signup {
  background-color: #ffe38c;
  border: 1px solid;
  border-radius: 30px;
  padding: 6px;
  font-size: 18px;
  font-weight: 600;
  margin-top: 15px;
}

.input-container {
  position: relative;
}

.input_password {
  position: absolute;
  top: 12px;
  left: 10px;
}

.input_icon {
  position: absolute;
  top: 4px;
  right: 9px;
  font-size: 22px;
  color: #b1b1b1;
}

.input_login {
  padding: 10px;
  width: 100%;
  padding-left: 10px;
  padding-right: 42px;
  font-size: 16px;
  color: black;
  border: 1px solid #b1b1b1;
}

.input_login_password {
  padding: 10px;
  width: 100%;
  padding-left: 90px;
  padding-right: 42px;
  font-size: 16px;
  color: black;
  border: 1px solid #b1b1b1;
}

.ps-sidebar-root {
  height: 100% !important;
}

.contact_1 {
  height: 250px;
  background-color: white;
  margin: 30px 30px 20px 30px;
  text-align: center;
  border-radius: 15px;
  padding: 10px 0px 20px 0px;
}

.contact_2 {
  height: 200px;
  background-color: white;
  margin: 0px 30px 20px 0px;
  border-radius: 15px;
  padding: 10px 30px 20px 20px;
}

.contact_3 {
  height: 200px;
  background-color: white;
  margin: 0px 0px 20px 30px;
  border-radius: 15px;
  padding: 10px 30px 20px 20px;
}

.contact_4 {
  height: 330px;
  background-color: white;
  margin: 0px 30px 20px 30px;
  text-align: center;
  border-radius: 15px;
  padding: 10px 0px 20px 0px;
}

.contact_title {
  font-size: 35px;
  font-weight: 800;
  color: #ffa800;
  line-height: 45px;
}

.contact_text {
  font-weight: 600;
  color: #666666;
}

.contact_text_bolt {
  font-weight: 700;
}

.man {
  width: 72%;
  height: 235px;
}

.arrow {
  width: 40%;
  margin: 30px;
  height: 160px;
}

.whistle2 {
  width: 90%;
  height: 80px;
}

.whistle3 {
  width: 90%;
  height: 100px;
}

.form_control_radus_2 {
  border-radius: 15px !important;
  border: black 1px solid !important;
  font-weight: 600 !important;
}

.contact_form {
  margin-top: 20px;
}

.div_button_contact {
  margin-top: 6px;
  margin-bottom: 20px;
}

.button_contact {
  background-color: #ffa800;
  border: 1px solid;
  border-radius: 15px;
  padding: 2px;
  font-size: 16px;
  font-weight: 600;
}

.sidebar_hr {
  margin: auto;
  margin-bottom: 5px;
  width: 220px;
  border-top: 2px solid #b1b1b1 !important;
}

.sidebar_hr_2 {
  margin-top: 0px;
  margin-right: 20px;
  margin-bottom: 5px;
  width: 150px;
  border-top: 2px solid #b1b1b1 !important;
}

.sidebar_hr_3 {
  margin-top: 8px !important;
  margin: auto;
  width: 220px;
  border-top: 2px solid #b1b1b1 !important;
}

.contact_hr {
  margin: auto;
  margin-bottom: 5px;
  width: 160px;
  border-top: 2px solid black !important;
}

.contact_hr_2 {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 400px;
  border-top: 2px solid black !important;
}

.popover {
  background-color: #f4bb16 !important;
  width: 240px !important;
}

.popover-body {
  padding: 3px !important;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Assistant', sans-serif;
  line-height: 12px;
}

.div_popover_link {
  padding: 3px;
  background-color: white;
  margin-top: 8px;
  border-radius: 15px;
  margin-left: 3px;
  text-align: center;
  border: 1px solid black;
}

.popover_link {
  color: black !important;
  font-weight: 700;
  text-decoration: none !important;
}

.popover_bold {
  font-weight: 700;
}

.no_padding_desk {
  --bs-gutter-x: 0rem !important;
}

.div_popover_button {
  position: absolute;
  left: 0;
  display: inline-block !important;
}

.div_popover_button_2 {
  position: absolute;
  left: 0;
  bottom: 5px;
  display: inline-block !important;
}

.popover_button {
  background-color: #ff000000;
  border: 0px solid !important;
}

.bord_700 {
  font-weight: 700;
}

.modal-footer {
  display: block !important;
}

.div_link_marketing {
  color: #f9a50a;
  font-size: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.link_marketing {
  color: #f9a50a !important;
  text-decoration: none !important;
  margin-right: 3px;
}

.stars {
  font-size: 20px;
}

.div_onboarding_title {
  color: #f3be17 !important;
  font-weight: 700 !important;
  border-right: 10px solid #f3be17 !important;
  padding-right: 8px;
}

.onboarding_text_1 {
  text-align: center;
}

.onboarding_text_2 {
  font-weight: 700;
  color: black;
  font-size: 18px;
  margin-bottom: 10px;
}

.onboarding_text_3 {
  margin-top: 10px;
}

.onboarding_text_4 {
  margin-top: 26px;
  margin-bottom: 4px;
}

.onboarding_icon_1 {
  color: #6f11aa;
  font-size: 17px;
  margin-right: 2px;
  margin-left: 2px;
}

.onboarding_icon_2 {
  color: #f9a50a;
  font-size: 19px;
  margin-right: 2px;
  margin-left: 2px;
}

.input_amount {
  border: 1px solid;
  border-radius: 15px;
  text-align: center;
  padding: 0px !important;
  width: 90% !important;
  color: black;
  margin: auto;
}

.Wellcome {
  background-image: url('../public/reka.webp');
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}

.wellcome_2 {
  background-color: #1a1c1ee2;
  height: 100%;
}

.div_Wellcome_1 {
  background-image: url('../public/wellcome.webp');
  background-size: cover;
  height: 47vh;
  width: 36vw;
  padding-top: 9%;
}

.wellcome_text {
  margin-right: 4rem;
  text-align: center;
}

.wellcome_text_1 {
  margin-top: 12px;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
}

.wellcome_text_2 {
  font-size: 45px;
  font-weight: 800;
  line-height: 36px;
}

.wellcome_text_3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  margin-top: 12px;
}

.div_icons {
  width: 80%;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
  color: white;
  line-height: 16px;
  font-weight: 600;
}

.wellcome_icon {
  text-align: center;
}

.wellcome_icon_2 {
  width: 64px;
  margin-bottom: 12px;
}

.color_y {
  color: #efb327
}

.wellcome_icon_3 {
  width: 73px;
  margin-bottom: 12px;
}


.div_button_wellcome {
  margin-top: 25px;
  margin-bottom: 4px;
  width: 76%;
  margin-right: auto;
  margin-left: auto;
}

.button_wellcome {
  background-color: #ffa800;
  border: 1px solid;
  border-radius: 30px;
  padding: 5px;
  font-size: 22px;
  font-weight: 700;
}

.div_button_wellcome_2 {
  margin-top: 2px;
  width: 44%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 50px;
}

.button_wellcome_2 {
  background-color: #00000000;
  color: #efb327;
  border: 1px solid;
  border-radius: 20px;
  padding: 4px;
  font-size: 14px;
}

.wellcome_hr {
  border-top: 2px solid #dcd7d7 !important;
}

.div_wellcome_hr {
  width: 70%;
  margin-right: auto;
  margin-left: auto;
  color: #bdb7b7;
  text-align: center;
}

.wellcome_hr_text {
  margin-top: 5px;
}

.wellcome_logo {
  width: 40%;
}

.wellcome_logo_2 {
  width: 60%;
  margin-right: 100px;
  margin-top: 10%;
}

.user_details_1 {
  height: 260px;
  background-color: white;
  margin-right: 30px;
  margin-top: 44px;
  border-radius: 15px;
}

.user_details_2 {
  height: 260px;
  background-color: white;
  margin-right: 30px;
  margin-top: 20px;
  border-radius: 15px;
}

.user_details_3 {
  height: 130px;
  background-color: white;
  margin-right: 30px;
  margin-top: 20px;
  border-radius: 15px;
  margin-bottom: 100px;
}

.user_details_1_title {
  background-color: #f9f9f9;
  border-radius: 15px 15px 0px 0px;
  border-bottom: 1px solid #d7d7d7;
  padding: 14px;
  font-weight: 600;
  font-size: 19px;
}

.user_details_form {
  padding: 10px;
}

.user_details_lable {
  font-weight: 600;
  margin-right: 4px;
  margin-top: 6px;
}

.user_details_input {
  padding: 10px;
  width: 100%;
  border-radius: 15px;
  height: 30px;
  color: #666666;
}

.div_button_user_details {
  margin-top: 160px;
  width: 44%;
  margin-right: auto;
  margin-left: 14px;
}

.div_button_user_details_2 {
  margin-top: 160px;
  width: 80%;
  margin-right: auto;
  margin-left: 14px;
}

.button_user_details {
  background-color: #ffe38c;
  border: 1px solid;
  border-radius: 20px;
  padding: 3px;
  font-size: 16px;
  font-weight: 600;
}

.div_button_user_details_3 {
  margin-top: 16px;
  width: 96%;
  margin-right: 16px
}

.button_user_details_3 {
  background-color: #f9f9f9;
  color: #f3be17;
  border: 1px solid #f3be17;
  border-radius: 20px;
  padding: 3px;
  font-size: 16px;
  font-weight: 600;
}

.test1 {
  height: 245px;
  width: 160px;
  top: 55px;
  left: 20px;
  position: absolute;
  border-radius: 15px;
  box-shadow: 1px 1px 1px 1px #d9d9d9;
}

.head_menu {
  height: 90px;
  background-color: #f9f9f9;
  border-radius: 15px 15px 0px 0px;
}

.body_menu {
  height: 120px;
  background-color: white;
}

.footer_menu {
  height: 35px;
  background-color: #f9f9f9;
  border-radius: 0px 0px 15px 15px;
  padding-top: 3px;
}

.button_menu {
  background-color: #00000000;
  border: 0px solid;
  font-size: 12px;
  padding: 5px;
}

.icon_menu {
  margin-right: 5px;
  margin-left: 5px;
}

.button_menu_2 {
  background-color: #00000000;
  border: 0px solid;
  font-size: 12px;
  font-weight: 700;
  padding: 5px;
  color: #ffa800;
}

.icon_menu_2 {
  margin-right: 5px;
  margin-left: 5px;
  color: #ffa800;
}

.div_button_nav_2 {
  text-align: left;
  margin-top: 20px;
  margin-left: 20px;
}

.button_nav_2 {
  background-color: #00000000;
  border: 0px solid;
  font-size: 12px;
  font-weight: 700;
  padding: 5px;
}

.icon_menu_3 {
  font-size: 35px !important;
}

.footer_hr {
  margin: auto;
  margin-bottom: 5px;
  width: 80%;
  border-top: 3px solid #898989 !important;
}

.footer_hr_wellcome {
  margin-top: 40px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 5px;
  width: 60%;
  border-top: 3px solid #898989 !important;
}

.Dashboard,
.BusinessDetails,
.BusinessProducts,
.MarketingBusiness,
.PropertyMarketings,
.UserDetails,
.ContactUs,
.Wellcome,
.wellcome_2 {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.Footer {
  margin-top: auto;
}

.footer {
  margin: auto;
  width: 60%;
  color: #898989 !important;
}

.footer_wellcome {
  margin: auto;
  width: 50%;
  color: #898989 !important;
}

.footer_image {
  margin-right: 10px;
}

.footer_link {
  margin-left: 10px;
  color: #898989 !important;
}

.footer_left {
  text-align: left;
  margin-left: 3px;
  color: #898989 !important;
}

.footer_right {
  text-align: right;
  margin-right: 3px;
  color: #898989 !important;
}

.footer_2 {
  margin: auto;
  width: 41%;
  color: #898989 !important;
  margin-bottom: 15px;
}

.footer_2_wellcome {
  margin: auto;
  width: 30%;
  color: #898989 !important;
  margin-bottom: 15px;
}

.img_before_upload {
  width: 90px;
  max-height: 90px;
}

.aco_bottom {
  margin-bottom: 20px;
}

.div_spinner {
  text-align: center;
}

.frame_height {
  height: 300px;
}

@media only screen and (max-width: 800px) {
  .table_title {
    margin-left: 20px !important;
    font-weight: 600;
    margin-left: 60px !important;
    width: 77%;
  }

  .table_title_1 {
    width: 40px;
    padding: 5px 0px 5px 5px;
  }

  .table_title_2 {
    width: 40px;
    padding: 5px 4px 5px 5px;
  }

  .table_title_3 {
    width: 40px;
    padding: 5px 10px 5px 5px;
  }

  .table_title_4 {
    width: 40px;
    padding: 5px 18px 5px 5px;
  }

  .frame_height {
    height: auto;
  }

  .button_edit_2 {
    height: 28px;
  }

  .man {
    width: 72%;
    height: 210px;
  }

  .arrow {
    width: 40%;
    margin: 30px;
    height: 130px;
  }

  .arrow {
    width: 30%;
    margin: 0px;
  }

  .contact_text {
    line-height: 16px;
  }

  .contact_text_2 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .whistle2 {
    margin-top: 20px;
  }

  .div_button_wellcome_2 {
    margin-top: 2px;
    width: 44%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
  }

  .div_button_wellcome {
    margin-top: 35px;
    margin-bottom: 0px;
  }

  .wellcome_hr_text {
    margin-top: 6px;
    font-size: 12px;
  }

  .wellcome_logo_2 {
    width: 90%;
    margin-right: 5%;
    margin-left: 5%;
    margin-top: 0px;
    border-radius: 15px;
  }

  .div_icons {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-right: auto;
    margin-left: auto;
    color: white;
    line-height: 16px;
    font-weight: 600;
    font-size: 13px;
  }

  .icon_margin {
    margin-top: 26px;
  }

  .wellcome_icon_2 {
    width: 60px;
    margin-bottom: 12px;
  }

  .wellcome_icon_3 {
    width: 69px;
    margin-bottom: 12px;
  }

  .wellcome_text_1 {
    margin-top: 8px;
    font-size: 29px;
    font-weight: 600;
    line-height: 36px;
  }

  .wellcome_text_2 {
    font-size: 43px;
    font-weight: 800;
    line-height: 34px;
  }

  .wellcome_text_3 {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    margin-top: 8px;
  }

  .div_Wellcome_1 {
    background-image: url('../public/wellcome.webp');
    background-size: cover;
    height: 40vh;
    width: 100vw;
    padding-top: 1px;
  }

  .wellcome_text {
    margin-top: 50px;
    margin-right: 0rem;
    text-align: center;
  }

  .footer_left {
    text-align: center;
  }

  .footer_right {
    text-align: center;
  }

  .contact_1 {
    height: auto;
    margin: 20px 0px 20px 0px;
  }

  .contact_2 {
    height: auto;
    margin: 0px 0px 20px 0px;
    padding: 10px 30px 20px 20px;
  }

  .contact_3 {
    height: auto;
    background-color: white;
    margin: 0px 0px 20px 0px;
    border-radius: 15px;
    padding: 10px 30px 20px 20px;
  }

  .contact_4 {
    height: auto;
    background-color: white;
    margin: 0px 0px 20px 0px;
    text-align: center;
    border-radius: 15px;
    padding: 10px 20px 10px 20px;
  }

  .contact_title {
    font-size: 32px;
    font-weight: 800;
    color: #ffa800;
    line-height: 45px;
  }

  .user_details_1 {
    height: auto;
    background-color: white;
    margin-right: 0px;
    margin-top: 20px;
    border-radius: 15px;
  }

  .user_details_2 {
    height: auto;
    background-color: white;
    margin-right: 0px;
    margin-top: 20px;
    border-radius: 15px;
  }

  .user_details_3 {
    height: auto;
    background-color: white;
    margin-right: 0px;
    margin-top: 20px;
    border-radius: 15px;
    margin-bottom: 20px
  }

  .div_button_user_details {
    margin-top: 20px;
    margin-bottom: 15px;
    width: 94%;
    margin-right: auto;
    margin-left: auto;
  }

  .div_button_user_details_2 {
    margin-top: 10px;
    margin-bottom: 15px;
    width: 94%;
    margin-right: auto;
    margin-left: auto;
  }

  .div_button_user_details_3 {
    margin-top: 16px;
    width: 96%;
    margin-right: auto;
    margin-left: auto;
  }

  .text_delete {
    width: 96%;
    margin-right: auto;
    margin-left: auto;
    font-size: 12px;
    line-height: 14px;
    color: #757575;
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .input_c_lable {
    font-size: 12px;
    margin-right: 5px;
    font-weight: 600;
    vertical-align: middle;
  }

  .signup_tytle {
    font-size: 32px;
    font-weight: 600;
    color: #f3be17;
    margin-top: 30px;
    margin-bottom: 0px;
    text-align: center;
  }

  .button_close {
    margin-right: 20px;
  }

  .text_table {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    font-size: 12px;
    padding: 5px 5px 3px 2px !important;
  }

  .test1 {
    left: 10px;
  }

  .div_button_nav_2 {
    margin-left: 8px;
  }

  .dashboard_title_head_2 {
    height: 50px;
    background-color: white;
  }

  .div_dashboard_logo {
    width: 30px;
    height: 30px;
    margin-top: 4px;
  }

  .dashboard_logo {
    width: 40px;
    height: 40px;
  }

  .tytle_dashboard {
    font-size: 18px;
    font-weight: 600;
    margin-top: 9px;
    line-height: 14px;
  }

  .tytle_dashboard_2 {
    font-size: 12px;
    margin-top: 4px;
    line-height: 12px;
  }

  .css-hirkb.ps-broken.ps-rtl.ps-toggled {
    width: 280px;
    margin-top: 0px;
  }

  .ps-sidebar-root {
    margin-top: 0px;
  }

  .margin_sidebar {
    height: 15px;
  }

  .dashboard_title_head {
    background-color: black;
  }

  .logo_nav_4 {
    text-align: center;
    margin-top: 13px;
  }

  .Footer {
    text-align: center;
  }

  .footer {
    width: 90%;
  }

  .footer_2 {
    width: 90%;
  }

  .footer_2_wellcome {
    width: 80%;
  }

  .aco {
    padding: 0px;
  }

  .target {
    margin: 20px 0px 0px 0px;
    height: 482px;
  }

  .target2 {
    margin: 20px 0px 0px 0px;
    background-color: white;
    border-radius: 15px;
    height: 200px;
    white-space: pre-line;
    line-height: 24px;
  }

  .target3 {
    margin: 20px 0px 0px 0px;
    background-color: white;
    border-radius: 15px;
    height: 200px;
    white-space: pre-line;
    line-height: 24px;
  }

  .target4 {
    margin: 20px 0px 0px 0px;
    background-color: white;
    border-radius: 15px;
    height: 855px;
    white-space: pre-line;
    line-height: 14px;
    margin-bottom: 15px;
  }

  .row {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .no_padding {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .modal-xl {
    margin-right: 0.5rem !important;
  }

  .modal-md {
    margin-right: 0.5rem !important;
  }

  .modal-sm {
    margin-right: 0.5rem !important;
  }

  .dashboard_marketing {
    padding: 5px;
  }

  .dashboard_div_marketing {
    margin-top: 15px;
  }

  .dashboard_div_marketing_2 {
    margin-top: 12px;
  }

  .dashbord_table_2 {
    max-height: 114px;
  }

  .table_margin {
    margin-top: 10px;
  }

  .title_head {
    height: 50px;
  }

  .div_title_head {
    padding-right: 5px;
    margin-top: 8px;
    margin-right: 15px;
    height: 34px;
  }

  .div_title_head_user {
    padding-right: 5px;
    margin-top: 8px;
    margin-right: 15px;
    height: 34px;
  }

  .title_head_text_user {
    line-height: 18px;
  }

  .title_head_text {
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
  }

  .upload_logo {
    margin-top: 20px;
  }

  .input_logo {
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .contact_hr_2 {
    width: 200px;
  }

  .contact_hr_3 {
    width: 200px;
  }

  .title_head_text_3 {
    font-size: 10px;
  }

  .div_logo_nav {
    margin: 13px;
  }

  .logo_nav {
    width: 150px;
  }

  .td_close {
    width: 60px;
  }

  .modal_bolt_3 {
    font-family: 'Assistant' !important;
    font-weight: 600 !important;
    color: black !important;
    font-size: 15px;
    margin-top: 30px;
    margin-bottom: 8px;
  }

}